@use '@angular/material' as mat;

$theme-primary-color-map: (
  50: var(--theme-primary-color-50),
  100: var(--theme-primary-color-100),
  200: var(--theme-primary-color-200),
  300: var(--theme-primary-color-300),
  400: var(--theme-primary-color-400),
  500: var(--theme-primary-color-500),
  600: var(--theme-primary-color-600),
  700: var(--theme-primary-color-700),
  800: var(--theme-primary-color-800),
  900: var(--theme-primary-color-900),
  A100: var(--theme-primary-color-A100),
  A200: var(--theme-primary-color-A200),
  A400: var(--theme-primary-color-A400),
  A700: var(--theme-primary-color-A700),
  contrast: (
    50: var(--theme-primary-color-contrast-50),
    100: var(--theme-primary-color-contrast-100),
    200: var(--theme-primary-color-contrast-200),
    300: var(--theme-primary-color-contrast-300),
    400: var(--theme-primary-color-contrast-400),
    500: var(--theme-primary-color-contrast-500),
    600: var(--theme-primary-color-contrast-600),
    700: var(--theme-primary-color-contrast-700),
    800: var(--theme-primary-color-contrast-800),
    900: var(--theme-primary-color-contrast-900),
    A100: var(--theme-primary-color-contrast-A100),
    A200: var(--theme-primary-color-contrast-A200),
    A400: var(--theme-primary-color-contrast-A400),
    A700: var(--theme-primary-color-contrast-A700),
  ),
);

$theme-accent-color-map: (
  50: var(--theme-accent-color-50),
  100: var(--theme-accent-color-100),
  200: var(--theme-accent-color-200),
  300: var(--theme-accent-color-300),
  400: var(--theme-accent-color-400),
  500: var(--theme-accent-color-500),
  600: var(--theme-accent-color-600),
  700: var(--theme-accent-color-700),
  800: var(--theme-accent-color-800),
  900: var(--theme-accent-color-900),
  A100: var(--theme-accent-color-A100),
  A200: var(--theme-accent-color-A200),
  A400: var(--theme-accent-color-A400),
  A700: var(--theme-accent-color-A700),
  contrast: (
    50: var(--theme-accent-color-contrast-50),
    100: var(--theme-accent-color-contrast-100),
    200: var(--theme-accent-color-contrast-200),
    300: var(--theme-accent-color-contrast-300),
    400: var(--theme-accent-color-contrast-400),
    500: var(--theme-accent-color-contrast-500),
    600: var(--theme-accent-color-contrast-600),
    700: var(--theme-accent-color-contrast-700),
    800: var(--theme-accent-color-contrast-800),
    900: var(--theme-accent-color-contrast-900),
    A100: var(--theme-accent-color-contrast-A100),
    A200: var(--theme-accent-color-contrast-A200),
    A400: var(--theme-accent-color-contrast-A400),
    A700: var(--theme-accent-color-contrast-A700),
  ),
);

//$theme-warn-color-map: $mat-red;

$theme-warn-color-map: (
  50: var(--theme-warn-color-50),
  100: var(--theme-warn-color-100),
  200: var(--theme-warn-color-200),
  300: var(--theme-warn-color-300),
  400: var(--theme-warn-color-400),
  500: var(--theme-warn-color-500),
  600: var(--theme-warn-color-600),
  700: var(--theme-warn-color-700),
  800: var(--theme-warn-color-800),
  900: var(--theme-warn-color-900),
  A100: var(--theme-warn-color-A100),
  A200: var(--theme-warn-color-A200),
  A400: var(--theme-warn-color-A400),
  A700: var(--theme-warn-color-A700),
  contrast: (
    50: var(--theme-warn-color-contrast-50),
    100: var(--theme-warn-color-contrast-100),
    200: var(--theme-warn-color-contrast-200),
    300: var(--theme-warn-color-contrast-300),
    400: var(--theme-warn-color-contrast-400),
    500: var(--theme-warn-color-contrast-500),
    600: var(--theme-warn-color-contrast-600),
    700: var(--theme-warn-color-contrast-700),
    800: var(--theme-warn-color-contrast-800),
    900: var(--theme-warn-color-contrast-900),
    A100: var(--theme-warn-color-contrast-A100),
    A200: var(--theme-warn-color-contrast-A200),
    A400: var(--theme-warn-color-contrast-A400),
    A700: var(--theme-warn-color-contrast-A700),
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary-palette: mat.define-palette($theme-primary-color-map);
$theme-accent-palette: mat.define-palette($theme-accent-color-map);

// The warn palette is optional (defaults to red).
$theme-warn-palette: mat.define-palette($theme-warn-color-map);

//custom colors
$white: #fff;
$black: #000;
$grey: grey;
$blue : blue;  
$lightgrey: #e0e0e0;
$purple: #947EFB;
$darkgreen: #027A48;

$progress-bg: #F0F0F5;
$progress-good: #38D525;
$progress-ok: #FFD500;
$progress-bad: #F20505;  
