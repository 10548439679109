@use 'sass:map';
@use '@angular/material' as mat;
@use 'colors';
//TODO: Make font family config
$theme: mat.define-light-theme((
  color: (
    primary: colors.$theme-primary-palette,
    accent: colors.$theme-accent-palette,
    warn: colors.$theme-warn-palette,
  )
));
$min-width: 350px;
$mobile-width: 650px;
$tablet-width: 1200px;
