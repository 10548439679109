/*icons*/
@font-face {
    font-family: 'icomoon';
    src:url('https://prweb-assets-prod.s3.amazonaws.com/relevant-hire-icons/font-sheet.ttf') format('truetype');
}

[class^="icon-"], [class*=" icon-"] 
{ 
	font-family: 'icomoon' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-match:before {
    content: '\e907';
}

.icon-check:before {
    content: '\e909';
}

.icon-heart:before {
	content: '\e906';
}

.icon-money:before {
	content: '\e904';
}

.icon-download:before {
	content: '\e903';
}

.icon-thumbs-up:before {
	content: '\e901';
}

.icon-search:before {
	content: '\e900';
}
.icon-location:before {
	content: '\e905';
}

.icon-cloud:before {
	content: '\e90a';
}

.icon-cloud-hollow:before {
	content: '\e90b';
}

.icon-home:before {
	content: '\e90e';
}

.icon-dashboard:before {
	content: '\e90c';
}

.icon-signout:before {
	content: '\e90f';
}

.icon-kebab:before {
	content: '\e902';
}

.icon-people:before {
	content: '\e90d';
}

.icon-lock:before {
	content: '\e910';
}

.icon-link:before {
	content: '\e911';
}