@use '@angular/material' as mat;
@use 'config';
@use 'colors';
//https://www.npmjs.com/package/angular-calendar
@use "../../../node_modules/angular-calendar/css/angular-calendar.css";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.legacy-core-theme(config.$theme);
@include mat.all-legacy-component-themes(config.$theme);

.cdk-custom-layer-backdrop {
  background: rgba(0,0,0,.11);
}

.mat-calendar {
  line-height: 1 !important;
}
/*error handler*/
app-error-summary {
  width: 100%;
  padding-bottom: 1.1rem;
}

.error-handler {
  padding-left: 1rem;
  background-color: colors.$white;
  border-left: 2px solid mat.get-color-from-palette(colors.$theme-warn-palette, 500);
  /*change the border when active*/
  .mat-form-field-underline {
      border-bottom: 1px solid mat.get-color-from-palette(colors.$theme-warn-palette, 500) !important;
  }
}

.error-handler__message {
  color: mat.get-color-from-palette(colors.$theme-warn-palette, 500);
  // padding-bottom: 0.3rem;
  font-size: 0.85rem;
  margin-block: 0;
}
.error-handler__details {
  color: mat.get-color-from-palette(colors.$theme-warn-palette, 500);
}

.error-summary__title {
  width: 100%;
  font-size: 1rem;
  background: mat.get-color-from-palette(colors.$theme-warn-palette, 500);
  color: mat.get-contrast-color-from-palette(colors.$theme-warn-palette, 500);
  padding: 20px 0;
  text-align: center;
}

.error-summary__list {
  list-style-type: none;
  margin: 0;
  padding: 30px;
  background-color: mat.get-contrast-color-from-palette(colors.$theme-warn-palette, 500);
}

.error-summary__list--thin {
  padding: 0 30px;
}

.error-summary__link {
  border-bottom: 1px solid mat.get-color-from-palette(colors.$theme-warn-palette, 500);
  font-size: 1rem;
  color: colors.$black;
  font-weight: normal !important;
  margin-bottom: 10px;
  cursor: pointer;
}

.error-summary__list-item {
  margin-bottom: 10px;
}

.error-summary__message {
    font-weight: bold;
    padding: 30px 30px 15px 30px;
    font-size: 1rem;
    color: colors.$black;
}

.error-summary__message--more-details {
  font-size: 1rem;
  padding: 0;
  font-weight: 500;
  color: mat.get-color-from-palette(colors.$theme-primary-palette, 300);
}

html {
  font-size: 16px;
  box-sizing: border-box;
}

body {
  line-height: 30px;
}

h2 {
  line-height: 1.15;
}

.loading-gif {
  /*margin: 0 auto;*/
  display: block;
}

.sr-only {
  position: absolute;
  left: -99999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
}

nav {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  list-style:none;

  ul {
      display:block;
  }
}

.clickable {
  cursor: pointer;
}

.center-loading-spinner {
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // transform: -webkit-translate(-50%, -50%);
  // transform: -moz-translate(-50%, -50%);
  // transform: -ms-translate(-50%, -50%);
    min-height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

html {
  text-rendering: optimizeSpeed;
  height: 100%;

  a.skip-link {
      left:-999px;
      position:absolute;
      top:auto;
      width:1px;
      height:1px;
      overflow:hidden;
      z-index:-999;
  }
  a.skip-link:focus, a.skip-main:active {
      color: #fff;
      background-color:rgba(0,0,0,0.9);
      left: auto;
      top: auto;
      width: 30%;
      height: auto;
      overflow:auto;
      margin: 10px 35%;
      padding:5px;
      text-align:center;
      font-size:1em;
      z-index:999;
  }
}

@media screen and (max-width: config.$mobile-width) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: config.$min-width) {
  body {
    min-width: config.$min-width;
  }
}

mat-drawer-content {
  background-color: colors.$white;
}

._editable__heading {
    font-weight: normal !important;
    text-transform: capitalize;
    font-size: 1rem;
    position: absolute;
    background-color: colors.$purple;
    padding: 0 10px;
    top: -12px;
    border-radius: 25px;
    color: #fff;
    box-sizing: border-box;
    display: flex;
}

._editable--selected, ._editable--selected:hover {
  background-color: lighten(colors.$darkgreen, 72%) !important;
  border-color: colors.$darkgreen !important;
}

._editable--selected ._editable__heading {
  background-color: colors.$darkgreen !important;
}

._editable:hover {
  background-color: lighten(colors.$purple, 20%);
}

._editable {
  overflow: visible !important;
  position: relative;
  padding: 14px;
  cursor: pointer;
  // height: 100%;
  border : 3px solid colors.$purple;
  border-radius: 0 !important;
}

._editable__logo {
  color: colors.$purple;
  position: absolute;
  font-size: 30px;
  background: colors.$white;
  cursor: pointer;
}

.disabled_div {
  pointer-events: none;
}

.text__edit-input {
  height: 2rem;
  padding: 14px;
  font-size: 2rem;
}

.section__edit-icon{
  cursor: pointer;
}

.ql-container.ql-snow{
  min-height: 300px;
}

.mat__config-toolbar {
  display: flex;
  background: #f5f5f5;
}

.preview__btn{
  font-size: 1rem;
}